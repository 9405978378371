import {
    handleQueryResolve
} from '../utils'

export default function (PatientID) {
  let where
  let attrs
  if (Array.isArray(PatientID)) {
    where = [`dos.PatientID IN (${PatientID.join(',')})`]
  } else {
    attrs = [{
      param: 'PatientID',
      type: 'sql.Int',
      value: PatientID, // to support like
    }]
    where = ['Rx.PatientID = @PatientID']
  }

  const query = `
        SELECT
            convert(varchar, Rx.RxDate, 23) as Date,
            count(convert(varchar, Rx.RxDate, 23)) as RxCount
        FROM
            Fillware.dbo.Rx
            LEFT JOIN Fillware.dbo.DrugRoot ON Fillware.dbo.Rx.DIN = Fillware.dbo.DrugRoot.DIN
        WHERE
            Rx.RxDate > DATEADD(month, -12, GetDate())
        AND
        ${where}
        GROUP BY convert(varchar, Rx.RxDate, 23)
          `

  //   console.log(query)

  return this.query(query, attrs).then(handleQueryResolve)
}
